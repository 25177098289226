<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Notifications
				button.btn.btn-icon.header-btn(v-if="selectedCount", v-b-tooltip.hover.left, title="Delete selected notifications", @click="deleteSelected()")
					i.la.la-trash
			.widget-body
				paginate(:paginator="paginate", @update-page-size="loadData")
				b-list-group
					notification-item(v-for="r in records", :notification="r", @toggle="onToggle(r)")

</template>
<style lang="scss">
.header-btn {
  font-size: 18px;
  padding: 0 0.75rem;
}
</style>

<script>
import NotificationItem from '../../components/NotificationItem';
import Vue from 'vue';

export default {
  name: 'NotificationList',
  components: { NotificationItem },
  data() {
    return {
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 10,
        onPageChange: () => {
          this.loadData();
        },
      },
      filters: {
        status: '',
        type: '',
      },
      records: [],
    };
  },

  computed: {
    selectedCount() {
      return this.records.filter((r) => r._selected).length;
    },
  },

  methods: {
    async loadData() {
      if (this.busy) {
        return;
      }
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        status: this.filters.status,
        type: 'info,alert,warning',
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('notification/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },

    onToggle: function (r) {
      Vue.set(r, '_selected', !r._selected);
    },

    deleteSelected: async function () {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete these ${this.selectedCount} notifications?`,
      );
      if (!confirmed) {
        return;
      }
      await this.$ovReq.post('notification/delete', {
        notification_ids: this.records
          .filter((n) => n._selected)
          .map((n) => n.id)
          .join(','),
      });
      this.$ovNotify.success(`Deleted successfully`);
      await this.loadData();
    },
  },
  created() {
    this.loadData();
  },
};
</script>
